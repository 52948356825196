@import "minima";

ul.post-list h2 {
  margin-bottom: 0px;
}

div.pagination {
  text-align: center;
}

blockquote {
  letter-spacing: inherit;
}
.post-list blockquote {
  font-size: inherit;
}

.truncate-content {
  margin-top: 15px;
}
